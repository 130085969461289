<template>
  <div class="invitel-is-center">
    <header class="invitel-is-header" >
      <van-icon name="arrow-left" class="icon" @click="goBack"/>
      <span>请输入邀请码</span>
      </header>

    <div class="inviter-title">请输入6位邀请码</div>

    <div class="row-center captcha_input_wrapper">

      <input 
        pattern="\d*"
        class="captcha_input_box row-center" 
        id="phoneCode1" 
        v-model="phoneCode0" 
        oninput="value=value.length>1?value.substr(0,1):value;"  
        maxlength="1" 
        type="number"/><input 
        pattern="\d*"
        class="captcha_input_box row-center" 
        id="phoneCode2"
        v-model="phoneCode1"  
        oninput="value=value.length>1?value.substr(0,1):value;"  
        maxlength="1" 
        type="number"/><input 
        pattern="\d*"
        class="captcha_input_box row-center" 
        id="phoneCode3" 
        v-model="phoneCode2" 
        oninput="value=value.length>1?value.substr(0,1):value;"  
        maxlength="1" 
        type="number"/><input 
        pattern="\d*"
        class="captcha_input_box row-center" 
        id="phoneCode4" 
        v-model="phoneCode3"  
        oninput="value=value.length>1?value.substr(0,1):value;"  
        maxlength="1" 
        type="number"/><input 
        pattern="\d*"
        class="captcha_input_box row-center" 
        id="phoneCode5" 
        v-model="phoneCode4" 
        oninput="value=value.length>1?value.substr(0,1):value;"  
        maxlength="1" 
        type="number"/><input 
        pattern="\d*"
        class="captcha_input_box row-center" 
        id="phoneCode6" 
        v-model="phoneCode5"  
        oninput="value=value.length>1?value.substr(0,1):value;"  
        maxlength="1" 
        type="number"/>

      
        
    </div>



    <div class="input-bottom">
      <div
        v-for="(item, index) in captchas"
        :key="index + 'x'"
        class="input-bottom-line"
      ></div>
    </div>

    <van-button class="btn" @click="goIndex">登录</van-button>

    
  </div>
</template>

<script>
export default {
  data() {
    return {
      captchas: [
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" },
      ],
      value: "",

            phoneCode:"",  // 验证码6位
            phoneCode0:"", // 输入框1的值
            phoneCode1:"", // 输入框2的值
            phoneCode2:"", // 输入框3的值
            phoneCode3:"", // 输入框4的值
            phoneCode4:"", // 输入框5的值
            phoneCode5:"", // 输入框6的值,
            submit:false
    };
  },
  created() {
    document.addEventListener("paste", (event) => {
      // 获取解析 粘贴的文本
      let text = (event.clipboardData || window.clipboardData)
        .getData("text")
        .split("");

        var txts = document.querySelectorAll('.captcha_input_box');// 获取所有input对象
      this.captchas.forEach((v, i) => {
        let str = 'phoneCode' + i
        this[str] = text[i];
       txts[i].blur()
      });

        
    });
  },
  mounted(){
     this.goNextInput('.captcha_input_box');// 实现输入框自动聚焦
  },

  methods: {
    goBack(){
      this.$router.replace({ path: "/login" });
    },
   
    goIndex() {
      let user = {
        userId: localStorage.getItem("id"),
        code: "",
      };
      for(let i =0; i<6;i++){
        let str = 'phoneCode' +i
        user.code += this[str]
      }
      // this.captchas.forEach((v) => {
      // });
      this.$api.login.bindingInvite(user).then((res) => {
        
        if (res.code === 200) {
          if(this.$route.query.redirect){
            this.$router.replace(`/${this.$route.query.redirect}?redirect=inviteIs`);
          }else{
          localStorage.setItem("token",localStorage.getItem('rtoken'))
            this.$router.push("/scene");
          }
     
        }
      });
    },
    onInput(key) {
      this.value = (this.value + key).slice(0, 6);
    },
    onDelete() {
      this.value = this.value.slice(0, this.value.length - 1);
    },
      goNextInput(el){ // 实现输入框自动聚焦
        console.log('输入',el);
            var that = this;
            var txts = document.querySelectorAll(el);// 获取所有input对象
            for(var i = 0; i<txts.length;i++){ // 循环input添加监听事件
              var t = txts[i];
              t.index = i; // input赋值索引值
              t.onkeydown=function(e){ // 键盘按下时
                var e = e ? e : event;
                var k = e.keyCode || e.which;
                if (k == 8) { // 如果是删除事件
                 if(this.value!=""){ // 如果当前input不为空
                  this.value = ""; // 清空当前input的值
                  switch(this.index) { // 根据索引值判断是当前哪个input的值需要被清空
                      case 0:
                          that.phoneCode0 ="";
                          break;
                      case 1:
                          that.phoneCode1 ="";
                          break;
                      case 2:
                          that.phoneCode2 ="";
                          break;
                      case 3:
                          that.phoneCode3 ="";
                          break;
                      case 4:
                          that.phoneCode4 ="";
                          break;
                      case 5:
                          that.phoneCode5 ="";
                          break;
                    } 
                 }else{ // 当前input为空,则聚焦到上一个input
                 var back = this.index-1;
                 that.value = "";
                 if(back <0) return; // 如果索引值小于0,则返回
                 txts[back].focus();
                 }
                }
              }
               t.onkeyup=function(e){ // 键盘松开时
                var e = e ? e : event;
                var k = e.keyCode || e.which;
                if (k != 8) { // 如果不是删除事件
                that.value=this.value.replace(/^(.).*$/,'$1');// 当前输入框保留一位数字
                var next = this.index + 1; // 索引值加1
                if(next > txts.length-1 ) // 如果是最后一个input时执行事件
                {   that.phoneCode = "";
                    that.phoneCode = that.phoneCode0+that.phoneCode1+that.phoneCode2+that.phoneCode3+that.phoneCode4+that.phoneCode5;
                    // that.CheckSmsCode(); // 验证验证码是否正确
                    return;
                }
                if (this.value) { txts[next].focus(); } // 如果有值则跳到下一个input
                }
             }
            }
         },

    
  },
};
</script>

<style lang='less' scope>
.invitel-is-center {
  height: 100vh;
  background: url("../../assets/images/color.png") no-repeat;
  background-size: 100%;
  background-attachment: fixed;
  background-color: #f5f7fb;

  .invitel-is-header {
    display: flex;

    align-items: center;
    justify-content: space-between;

    padding: 0.42rem .3rem 0;

    font-size: 0.36rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 0.5rem;
    span{
      text-align: center;
      flex: 1;
    }
  }

  .inviter-title {
    margin: 2.3rem 0 0.8rem;
    text-align: center;
    font-size: 0.4rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 0.3rem;
  }

  .btn {
    margin: 0.6rem /* 30/50 */ 0.6rem /* 30/50 */ 0.3rem /* 15/50 */;
    width: 6.3rem /* 315/50 */;
    height: 0.96rem /* 48/50 */;
    background: linear-gradient(0deg, #3b5bff, #7399f5);
    border-radius: 5px;
    font-size: 0.36rem /* 18/50 */;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    border-radius: 0.48rem;
  }
}
// .van-password-input__security,
// .van-password-input__item {
//   &::after {
//     display: none;
//   }
// }
// .van-password-input__item {
//   margin: 0 5px;
//   background-color: transparent !important;
//   border-bottom:  1px solid #dae8ff;
// }

.row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.captcha_input_wrapper {
  margin-left: 0.74rem;
  width: 6rem;
}
.captcha_input_box {
  // position: relative;
  width: 0.6rem;
  height: 10vw;
  margin: 0.1rem 0.2rem;
  // background: rgba(255, 255, 255, 1);
  // border-radius: 6px;
  // border: 1px solid #dddddd;
  border: none;
  background-color: transparent;
  font-size: 0.4rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 0.3rem;
  text-align: center;
}
.input-bottom {
  display: flex;
  margin-left: 0.78rem;
  .input-bottom-line {
    margin: 0 0.2rem;
    width: 0.6rem;
    height: 0.04rem;
    background: #dae8ff;
    border-radius: 0.02rem;
  }
}
</style>
